<script>
import ConsignacaoForm from '@/components/shared/consignacao/consignacaoForm.vue'

export default {
  extends: ConsignacaoForm,

  methods: {
    carregarRubricas() {
      const atividades = [
        'EMPRESTIMO',
        'PLANO DE SAUDE',
        'ASSOCIACAO',
        'PLANO FUNERARIO',
        'SEGURO',
        'SINDICATO',
        'CARTAO DE CREDITO',
        'ADIANTAMENTO',
      ]
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )
      this.rubricaService
        .getListaRubricasAtivasPorAtividadesDesconsiderarRubrica(
          atividades,
          this.matricula.id,
          consignataria.id,
        )
        .then(
          (res) => {
            this.rubricas = res
          },
          (err) => {
            this.exibeToast('error', err.response.data.message)
          },
        )
    },
  },
}
</script>
