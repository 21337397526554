<template>
  <div>
    <Panel header="Averbação" class="mt-3">
      <form @submit.prevent="validate()">
        <Toolbar v-if="tipo === 'Averbar'" class="mb-4">
          <template #start>
            <Button
              label="Voltar"
              icon="pi pi-chevron-left"
              class="mt-2 mr-2 p-button-sm p-button-secondary"
              @click="$router.back()" />
          </template>
        </Toolbar>
        <div class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="rubrica" class="required">Rubrica</label>
            <Dropdown
              id="rubrica"
              v-model="v$.consignacao.rubrica.$model"
              :class="{
                'p-invalid': submitted && v$.consignacao.rubrica.$invalid,
              }"
              optionLabel="nome"
              :options="rubricas"
              placeholder="Selecione uma rubrica"
              @change="tratarCarencia()">
              <template #value="slotProps">
                <div v-if="slotProps.value.rubrica">
                  <span>
                    {{ slotProps.value.rubrica }} | {{ slotProps.value.nome }} |
                    {{ slotProps.value.atividade.nome }}
                  </span>
                </div>
                <div v-else>
                  <span>{{ slotProps.placeholder }}</span>
                </div>
              </template>

              <template #option="slotProps">
                <div>
                  <span>
                    {{ slotProps.option.rubrica }} |
                    {{ slotProps.option.nome }} |
                    {{ slotProps.option.atividade.nome }}
                  </span>
                </div>
              </template>
            </Dropdown>
            <div
              v-if="submitted && v$.consignacao.rubrica.required.$invalid"
              class="p-error">
              O campo rubrica é obrigatório.
            </div>
          </div>
          <div class="field col-6 md:col-6">
            <label for="numeroContrato" class="required">Nº Contrato</label>
            <InputText
              id="numeroContrato"
              v-model="v$.consignacao.numeroContrato.$model"
              :class="{
                'p-invalid':
                  submitted && v$.consignacao.numeroContrato.$invalid,
              }"
              type="text"
              placeholder="Digite o número contrato" />
            <div
              v-if="
                submitted && v$.consignacao.numeroContrato.required.$invalid
              "
              class="p-error">
              O campo número contrato é obrigatório.
            </div>
            <div
              v-if="
                submitted &&
                v$.consignacao.numeroContrato.tamanhoCampoNumeroContrato
                  .$invalid
              "
              class="p-error">
              O campo número contrato não pode ter mais de 20 caracteres.
            </div>
          </div>
        </div>
        <div class="p-fluid formgrid grid">
          <div class="field col-6 md:col-6">
            <label for="valorParcela" class="required">Valor Parcela</label>
            <div class="p-inputgroup">
              <span class="p-inputgroup-addon">R$</span>
              <InputNumber
                id="valorParcela"
                v-model="v$.consignacao.valorParcela.$model"
                mode="decimal"
                locale="pt-BR"
                :minFractionDigits="2"
                :maxFractionDigits="5"
                :class="{
                  'p-invalid':
                    submitted && v$.consignacao.valorParcela.$invalid,
                }"
                placeholder="0,00" />
            </div>
            <div
              v-if="submitted && v$.consignacao.valorParcela.required.$invalid"
              class="p-error">
              O campo valor parcela deve conter um número superior ou igual a 1.
            </div>
          </div>
          <div class="field col-3 md:col-3">
            <label for="prazoTotal" class="required">Prazo</label>
            <InputNumber
              id="prazoTotal"
              v-model="v$.consignacao.prazoTotal.$model"
              :disabled="prazoCarenciaDisabled"
              :min="0"
              :max="999"
              :class="{
                'p-invalid': submitted && v$.consignacao.prazoTotal.$invalid,
              }" />
            <div
              v-if="submitted && v$.consignacao.prazoTotal.required.$invalid"
              class="p-error">
              O campo prazo total é obrigatório.
            </div>
          </div>
          <div class="field col-3 md:col-3">
            <label for="carencia" class="required">
              Data da Primeira Parcela:
            </label>
            <Dropdown
              id="carencia"
              v-model="v$.consignacao.carencia.$model"
              :options="datasCarencia"
              optionLabel="smesCarencia"
              :disabled="prazoCarenciaDisabled"
              placeholder="Selecione uma carência"
              :class="{
                'p-invalid': submitted && v$.consignacao.carencia.$invalid,
              }">
            </Dropdown>
            <div
              v-if="submitted && v$.consignacao.carencia.required.$invalid"
              class="p-error">
              O campo carência é obrigatório
            </div>
          </div>
        </div>
        <Button
          type="submit"
          autofocus
          :disabled="saving"
          @click="adicionarAverbacao()">
          <span v-if="!saving" class="pi pi-dollar"></span>
          <span v-if="saving" class="pi pi-spin pi-spinner"></span>
          <span v-if="!saving" class="ml-2">{{ nomeBotao }}</span>
          <span v-if="saving" class="ml-2">Aguarde</span>
        </Button>
      </form>
    </Panel>

    <modal-senha-servidor
      :exibir="exibirModalSenhaServidor"
      :metodoSalvar="salvar"
      :tipo="true"
      :isNovaAverbacao="true"
      :showConsignacao="novaAverbacao"
      @mudarVisibilidade="
        exibirModalSenhaServidor = $event
      "></modal-senha-servidor>
  </div>
</template>

<script>
import UseVuelidate from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import Consignacao from '@/domain/Consignacao.js'
import ConsignacaoService from '@/service/ConsignacaoService.js'
import RubricaService from '@/service/RubricaService.js'
import UtilService from '@/service/UtilService'
import ModalSenhaServidor from '@/components/shared/modal/modalSenhaServidor.vue'
import { servidorStore } from '@/stores/servidor'

const tamanhoCampoNumeroContrato = (value) =>
  checkTamanhoCampoNumeroContrato(value)

function checkTamanhoCampoNumeroContrato(value) {
  return value.length <= 20 ? true : false
}

export default {
  components: {
    'modal-senha-servidor': ModalSenhaServidor,
  },
  props: {
    tipo: {
      type: String,
      default: null,
    },
    metodoSalvarRenegociacao: {
      type: Function,
      default: null,
    },
  },

  setup() {
    const storeServidor = servidorStore()
    return { storeServidor, v$: UseVuelidate() }
  },

  data() {
    return {
      consignacao: new Consignacao(),
      exibirModalSenhaServidor: false,
      submitted: false,
      submitStatus: null,
      matricula: {
        id: this.$route.params.id,
      },
      rubricas: [
        {
          nome: '',
        },
      ],
      idMatricula: this.$route.params.id,
      saving: false,
      prazoCarenciaDisabled: false,
      novaAverbacao: {},
      prazoCarencia: 12,
      datasCarencia: [
        {
          smesCarencia: '',
          mesReferencia: 0,
          anoReferencia: 0,
        },
      ],
    }
  },

  computed: {
    nomeBotao() {
      return this.tipo
    },
  },

  created() {
    this.consignacaoService = new ConsignacaoService(this.$http)
    this.rubricaService = new RubricaService(this.$http)
    this.utilService = new UtilService(this.$http)
  },

  mounted() {
    this.carregarRubricas()
  },

  validations() {
    return {
      consignacao: {
        rubrica: {
          required,
        },
        numeroContrato: {
          required,
          tamanhoCampoNumeroContrato,
        },
        valorParcela: {
          required,
        },
        prazoTotal: {
          required,
        },
        carencia: {
          required,
        },
      },
    }
  },

  methods: {
    carregarRubricas() {
      const atividades = [
        'EMPRESTIMO',
        'PLANO DE SAUDE',
        'ASSOCIACAO',
        'PLANO FUNERARIO',
        'SEGURO',
        'SINDICATO',
      ]
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )
      this.rubricaService
        .getListaRubricasAtivasPorAtividades(
          atividades,
          this.matricula.id,
          consignataria.id,
        )
        .then(
          (res) => {
            this.rubricas = res
          },
          (err) => {
            this.exibeToast('error', err.response.data.message)
          },
        )
    },

    validate() {
      this.submitted = true
      this.v$.consignacao.$touch()
      if (this.v$.consignacao.$invalid) {
        return
      } else {
        this.exibirModalSenhaServidor = true
      }
    },

    salvar() {
      this.atribuirDados()
      this.saving = true
      const consignataria = JSON.parse(
        localStorage.getItem('consignatariaSelecionada'),
      )
      if (this.tipo !== null && this.tipo === 'Renegociar') {
        this.metodoSalvarRenegociacao(this.consignacao)
        this.limparCampos()
      } else {
        this.consignacaoService
          .saveConsignacao(this.consignacao, consignataria.id)
          .then(
            (res) => {
              this.storeServidor.dados = res.data.matricula
              this.exibeToast('success')
              this.limparCampos()
            },
            (err) => {
              this.exibeToast('error', err.response.data.message)
              this.saving = false
            },
          )
      }
    },

    atribuirDados() {
      this.consignacao.matricula = this.matricula
    },

    limparCampos() {
      this.rubricas = [
        {
          nome: '',
        },
      ]
      this.carregarRubricas()
      this.submitted = false
      this.saving = false
      this.v$.$reset()
      this.consignacao = new Consignacao()
      this.datasCarencia = [
        {
          smesCarencia: '',
          mesReferencia: 0,
          anoReferencia: 0,
        },
      ]
    },

    exibeToast(tipo, msg) {
      if (tipo === 'success') {
        this.$toast.add({
          severity: 'success',
          summary: 'Averbação realizada com sucesso!',
          life: 10000,
        })
      } else if (tipo === 'error') {
        this.$toast.add({
          severity: 'error',
          summary: msg,
          life: 10000,
        })
      }
    },

    adicionarAverbacao() {
      this.novaAverbacao.rubrica = String(
        this.consignacao.rubrica.rubrica,
      ).concat(
        ' | ',
        this.consignacao.rubrica.nome,
        ' | ',
        this.consignacao.rubrica.atividade?.nome,
      )
      this.novaAverbacao.numeroContrato = this.consignacao.numeroContrato
      this.novaAverbacao.valorParcela = parseFloat(
        this.consignacao.valorParcela,
      ).toFixed(2)
      this.novaAverbacao.prazoTotal = this.consignacao.prazoTotal
      this.novaAverbacao.carencia = this.consignacao.carencia.smesCarencia
      this.novaAverbacao.mesReferencia = this.consignacao.carencia.mesReferencia
      this.novaAverbacao.anoReferencia = this.consignacao.carencia.anoReferencia
      this.salvarMesEAnoReferencia()
    },

    tratarCarencia() {
      const date = new Date()
      let currentDay = date.getDate()
      let currentMonth = date.getMonth() + 1
      let currentYear = date.getFullYear()
      let novasDatasCarencia = []
      let mesLimite = 12
      let carenciaMaxima = 6
      let diaCorte = this.consignacao.rubrica.entidade.diaCorte

      if (currentDay > diaCorte) {
        currentMonth++
      }
      if (currentMonth > mesLimite) {
        currentMonth -= 12
        currentYear++
      }
      novasDatasCarencia.push({
        smesCarencia: String(currentMonth).concat('/', currentYear),
        mesReferencia: currentMonth,
        anoReferencia: currentYear,
        carencia: 0,
      })

      for (let mes = 1; mes <= carenciaMaxima; mes++) {
        currentMonth++

        if (currentMonth > this.mesLimite) {
          currentMonth -= this.mesLimite
          currentYear++
        }
        novasDatasCarencia.push({
          smesCarencia: String(currentMonth).concat('/', currentYear),
          mesReferencia: currentMonth,
          anoReferencia: currentYear,
          carencia: mes,
        })
      }
      this.datasCarencia = novasDatasCarencia
      this.consignacao.carencia = this.datasCarencia[0]
    },

    salvarMesEAnoReferencia() {
      this.consignacao.mesReferencia = this.consignacao.carencia.mesReferencia
      this.consignacao.anoReferencia = this.consignacao.carencia.anoReferencia
      this.consignacao.carencia = this.consignacao.carencia.carencia
    },
  },
}
</script>

<style scoped>
.required:after {
  content: ' *';
  color: red;
}
</style>
