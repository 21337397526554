var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('Panel',{staticClass:"mt-3",attrs:{"header":"Averbação"}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.validate()}}},[(_vm.tipo === 'Averbar')?_c('Toolbar',{staticClass:"mb-4",scopedSlots:_vm._u([{key:"start",fn:function(){return [_c('Button',{staticClass:"mt-2 mr-2 p-button-sm p-button-secondary",attrs:{"label":"Voltar","icon":"pi pi-chevron-left"},on:{"click":function($event){return _vm.$router.back()}}})]},proxy:true}],null,false,1336647008)}):_vm._e(),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"rubrica"}},[_vm._v("Rubrica")]),_c('Dropdown',{class:{
              'p-invalid': _vm.submitted && _vm.v$.consignacao.rubrica.$invalid,
            },attrs:{"id":"rubrica","optionLabel":"nome","options":_vm.rubricas,"placeholder":"Selecione uma rubrica"},on:{"change":function($event){return _vm.tratarCarencia()}},scopedSlots:_vm._u([{key:"value",fn:function(slotProps){return [(slotProps.value.rubrica)?_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.value.rubrica)+" | "+_vm._s(slotProps.value.nome)+" | "+_vm._s(slotProps.value.atividade.nome)+" ")])]):_c('div',[_c('span',[_vm._v(_vm._s(slotProps.placeholder))])])]}},{key:"option",fn:function(slotProps){return [_c('div',[_c('span',[_vm._v(" "+_vm._s(slotProps.option.rubrica)+" | "+_vm._s(slotProps.option.nome)+" | "+_vm._s(slotProps.option.atividade.nome)+" ")])])]}}]),model:{value:(_vm.v$.consignacao.rubrica.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.rubrica, "$model", $$v)},expression:"v$.consignacao.rubrica.$model"}}),(_vm.submitted && _vm.v$.consignacao.rubrica.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo rubrica é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"numeroContrato"}},[_vm._v("Nº Contrato")]),_c('InputText',{class:{
              'p-invalid':
                _vm.submitted && _vm.v$.consignacao.numeroContrato.$invalid,
            },attrs:{"id":"numeroContrato","type":"text","placeholder":"Digite o número contrato"},model:{value:(_vm.v$.consignacao.numeroContrato.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.numeroContrato, "$model", $$v)},expression:"v$.consignacao.numeroContrato.$model"}}),(
              _vm.submitted && _vm.v$.consignacao.numeroContrato.required.$invalid
            )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo número contrato é obrigatório. ")]):_vm._e(),(
              _vm.submitted &&
              _vm.v$.consignacao.numeroContrato.tamanhoCampoNumeroContrato
                .$invalid
            )?_c('div',{staticClass:"p-error"},[_vm._v(" O campo número contrato não pode ter mais de 20 caracteres. ")]):_vm._e()],1)]),_c('div',{staticClass:"p-fluid formgrid grid"},[_c('div',{staticClass:"field col-6 md:col-6"},[_c('label',{staticClass:"required",attrs:{"for":"valorParcela"}},[_vm._v("Valor Parcela")]),_c('div',{staticClass:"p-inputgroup"},[_c('span',{staticClass:"p-inputgroup-addon"},[_vm._v("R$")]),_c('InputNumber',{class:{
                'p-invalid':
                  _vm.submitted && _vm.v$.consignacao.valorParcela.$invalid,
              },attrs:{"id":"valorParcela","mode":"decimal","locale":"pt-BR","minFractionDigits":2,"maxFractionDigits":5,"placeholder":"0,00"},model:{value:(_vm.v$.consignacao.valorParcela.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.valorParcela, "$model", $$v)},expression:"v$.consignacao.valorParcela.$model"}})],1),(_vm.submitted && _vm.v$.consignacao.valorParcela.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo valor parcela deve conter um número superior ou igual a 1. ")]):_vm._e()]),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{staticClass:"required",attrs:{"for":"prazoTotal"}},[_vm._v("Prazo")]),_c('InputNumber',{class:{
              'p-invalid': _vm.submitted && _vm.v$.consignacao.prazoTotal.$invalid,
            },attrs:{"id":"prazoTotal","disabled":_vm.prazoCarenciaDisabled,"min":0,"max":999},model:{value:(_vm.v$.consignacao.prazoTotal.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.prazoTotal, "$model", $$v)},expression:"v$.consignacao.prazoTotal.$model"}}),(_vm.submitted && _vm.v$.consignacao.prazoTotal.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo prazo total é obrigatório. ")]):_vm._e()],1),_c('div',{staticClass:"field col-3 md:col-3"},[_c('label',{staticClass:"required",attrs:{"for":"carencia"}},[_vm._v(" Data da Primeira Parcela: ")]),_c('Dropdown',{class:{
              'p-invalid': _vm.submitted && _vm.v$.consignacao.carencia.$invalid,
            },attrs:{"id":"carencia","options":_vm.datasCarencia,"optionLabel":"smesCarencia","disabled":_vm.prazoCarenciaDisabled,"placeholder":"Selecione uma carência"},model:{value:(_vm.v$.consignacao.carencia.$model),callback:function ($$v) {_vm.$set(_vm.v$.consignacao.carencia, "$model", $$v)},expression:"v$.consignacao.carencia.$model"}}),(_vm.submitted && _vm.v$.consignacao.carencia.required.$invalid)?_c('div',{staticClass:"p-error"},[_vm._v(" O campo carência é obrigatório ")]):_vm._e()],1)]),_c('Button',{attrs:{"type":"submit","autofocus":"","disabled":_vm.saving},on:{"click":function($event){return _vm.adicionarAverbacao()}}},[(!_vm.saving)?_c('span',{staticClass:"pi pi-dollar"}):_vm._e(),(_vm.saving)?_c('span',{staticClass:"pi pi-spin pi-spinner"}):_vm._e(),(!_vm.saving)?_c('span',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.nomeBotao))]):_vm._e(),(_vm.saving)?_c('span',{staticClass:"ml-2"},[_vm._v("Aguarde")]):_vm._e()])],1)]),_c('modal-senha-servidor',{attrs:{"exibir":_vm.exibirModalSenhaServidor,"metodoSalvar":_vm.salvar,"tipo":true,"isNovaAverbacao":true,"showConsignacao":_vm.novaAverbacao},on:{"mudarVisibilidade":function($event){_vm.exibirModalSenhaServidor = $event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }